import { createRouter, createWebHistory } from 'vue-router'
import FormStart from '@/views/FormStart.vue'
import FormDoorSelect from '@/views/FormDoorSelect.vue'
import FormSuccess from '@/views/FormSuccess.vue'
import Home from '@/views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/start',
    name: 'form_start',
    component: FormStart
  },
  {
    path: '/door_photo',
    name: 'door_photo',
    component: () => import(/* webpackChunkName: "rfq-form-door-photo" */ '@/views/FormDoorPhoto.vue'),
  },
  {
    path: '/door_select',
    name: 'door_select',
    component: FormDoorSelect
  },
  {
    path: '/rfq_details',
    name: 'rfq_details',
    component: () => import(/* webpackChunkName: "rfq-form-rfq-details" */ '@/views/FormRfqDetails.vue'),
  },
  {
    path: '/success',
    name: 'success',
    component: FormSuccess,
  },
]

const routePrefix = process.env.VUE_APP_BASE_URL
const router = createRouter({
  history: createWebHistory(routePrefix),
  routes
})

export default router;

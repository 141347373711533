<template>
  <form
    id="doorTypeForm"
    @submit.prevent="onSubmit"
    novalidate
    class="container-fluid h-100 py-3"
  >
    <div class="d-flex flex-column h-100 justify-content-start">
      <h4 class="text-center w-75 w-sm-100 align-self-center">
        Please select a door to capture:
      </h4>
      <div class="card form-card">
        <div class="card-body form-card-body">
          <div
            class="btn-group-vertical btn-group-lg btn-group-toggle w-100"
            data-toggle="buttons"
          >
            <div class="small text-danger">*Unit Entry Door is required</div>
            <label
              class="d-flex justify-content-around btn btn-light my-2 rounded"
              :class="{
                active: option.doorTypeId == data.selectedDoorType,
                completed: isDoorTypeCompleted(option.doorTypeId),
                disabled: isDoorTypeCompleted(option.doorTypeId),
              }"
              v-for="option in data.doorTypes"
              :key="option"
              :disabled="isDoorTypeCompleted(option.doorTypeId)"
              @click="selectDoorType(option.doorTypeId)"
            >
              <input
                type="radio"
                v-model="data.selectedDoorType"
                :value="option.doorTypeId"
                :disabled="
                  option.doorTypeId <= 0 ||
                  isDoorTypeCompleted(option.doorTypeId)
                "
              />
              <div v-show="isDoorTypeCompleted(option.doorTypeId)"></div>
              <div class="mx-auto">{{ option.doorTypeDesc }}</div>
              <div
                class="text-success align-self-center"
                v-show="isDoorTypeCompleted(option.doorTypeId)"
              >
                <BIconCheckCircleFill />
              </div>
            </label>
          </div>
          <div class="d-flex justify-content-center">
            <button
              @click="onContinueClick"
              class="btn control-btn px-5 mx-auto my-3"
              :disabled="!isDoorTypeCompleted(1)"
            >
              <div class="d-flex align-items-center justify-content-center">
                <div class="p-2">Continue</div>
                <BIconCaretRightFill />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { useRouter } from "vue-router";
import { reactive, inject } from "vue";
import api from "@/resources/axios";

export default {
  name: "FormDoorSelect",
  setup() {
    const axios = api;
    const store = inject("store");
    const router = useRouter();

    const data = reactive({
      selectedDoorType: null,
      state: store.state,
      doorTypes: [
        {
          doorTypeId: "0",
          doorTypeDesc: "Loading...",
        },
      ],
    });

    const selectDoorType = (doorTypeId) => {
      if (isDoorTypeCompleted(doorTypeId)) {
        return;
      } else {
        onSubmit();
      }
    };

    const getSelectedDoor = () => {
      return data.doorTypes.find(
        (obj) => obj.doorTypeId == data.selectedDoorType
      );
    };

    const isDoorTypeCompleted = (doorTypeId) => {
      return data.state.completedDoors.indexOf(doorTypeId) != -1;
    };

    const onSubmit = () => {
      store.setLoading(true);
      // simulate api call
      setTimeout(function () {
        var selectedDoor = getSelectedDoor();

        store.selectDoorType(selectedDoor);
        store.setLoading(false);
        router.push({ path: "door_photo" });
      }, 100);
    };

    const onContinueClick = (event) => {
      event.preventDefault();
      router.push({ path: "rfq_details" });
    };

    store.setLoading(true);
    axios
      .get("DoorTypes")
      .then((response) => {
        data.doorTypes = response.data;
      })
      .catch((error) => {
        store.toast.error("Server error occurred.");
        console.log("Error", error);
      })
      .then(function () {
        store.setLoading(false);
      });

    return {
      data,
      onSubmit,
      isDoorTypeCompleted,
      selectDoorType,
      onContinueClick,
    };
  },
};
</script>

<style scoped>
.active {
  border: 3px solid #17a2b8 !important;
}

.completed {
  border: 3px solid #28a745 !important;
}

.btn-group-lg .btn {
  font-size: 1.7rem;
}
</style>
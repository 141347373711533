<template>
  <button class="btn control-btn" id="begin-btn" @click="onClick">
    <div class="d-flex align-items-center justify-content-center">
      <div class="p-2">BEGIN NEW RFQ</div>
      <BIconPlusCircleFill />
    </div>
  </button>
</template>

<script>
export default {
  name: "Home",
  inject: ["store"],
  methods: {
    onClick() {
      this.$recaptcha("login")
        .then(() => {
          this.store.startApp();
          this.$router.push({ path: "/start" });
        })
        .catch((error) => {
          this.store.toast.error("Error occurred.");
          console.log("Error verifying recaptcha", error);
        });
    },
  },
};
</script>
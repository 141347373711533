<template>
  <div v-if="store.state.isLoading">
    <div class="loader"></div>
    <div class="backgroundCover"></div>
  </div>
</template>

<script>
export default {
  name: "LoadingOverlay",
  inject: ["store"],
};
</script>

<style lang="scss" scoped>
.loader {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  z-index: 2999;
}

.backgroundCover {
  position: absolute;
  z-index: 2998;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.75);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
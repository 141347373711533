<template>
  <div class="container-fluid h-100 py-3">
    <div class="d-flex flex-column h-100 justify-content-start">
      <h4 class="text-center w-75 w-sm-100 align-self-center">
        Please fill out the information below:
      </h4>
      <div class="card form-card">
        <div class="card-body form-card-body">
          <form
            id="rfqCreateForm"
            class="h-100 w-100"
            @submit.prevent="onSubmit"
            novalidate
          >
            <div class="form-group w-100">
              <input
                type="text"
                autocomplete="on"
                name="propertyName"
                ref="propertyNameRef"
                :class="{
                  'is-invalid':
                    vv.propertyName.$dirty && vv.propertyName.$invalid,
                }"
                class="form-control form-control-lg"
                placeholder="Property Name"
                v-model.trim="vv.propertyName.$model"
              />
              <div class="invalid-feedback">
                {{ vv?.propertyName?.$errors[0]?.$message }}
              </div>
            </div>
            <div class="form-group w-100">
              <input
                type="text"
                name="propertyAddress"
                :class="{
                  'is-invalid':
                    vv.propertyAddress.$dirty && vv.propertyAddress.$invalid,
                }"
                class="form-control form-control-lg"
                placeholder="Property Address"
                v-model.trim="vv.propertyAddress.$model"
              />
              <div class="invalid-feedback">
                {{ vv?.propertyAddress?.$errors[0]?.$message }}
              </div>
            </div>
            <div class="form-group w-100">
              <input
                type="text"
                name="contactName"
                :class="{
                  'is-invalid':
                    vv.contactName.$dirty && vv.contactName.$invalid,
                }"
                class="form-control form-control-lg"
                placeholder="Contact Name"
                v-model.trim="vv.contactName.$model"
              />
              <div class="invalid-feedback">
                {{ vv?.contactName?.$errors[0]?.$message }}
              </div>
            </div>
            <div class="form-group w-100">
              <input
                type="text"
                name="contactNumber"
                :class="{
                  'is-invalid':
                    vv.contactNumber.$dirty && vv.contactNumber.$invalid,
                }"
                class="form-control form-control-lg"
                placeholder="Contact Number"
                v-model.trim="vv.contactNumber.$model"
              />
              <div class="invalid-feedback">
                {{ vv?.contactNumber?.$errors[0]?.$message }}
              </div>
              <div
                class="invalid-feedback"
                v-if="
                  vv.contactNumber.validPhoneNumber.$invalid &&
                  !vv.contactNumber.required.$invalid
                "
              >
                Please enter a valid phone number
              </div>
            </div>

            <div class="form-group w-100">
              <input
                type="text"
                name="contactEmail"
                :class="{
                  'is-invalid':
                    vv.contactEmail.$dirty && vv.contactEmail.$invalid,
                }"
                class="form-control form-control-lg"
                placeholder="Contact Email"
                v-model.trim="vv.contactEmail.$model"
              />
              <div class="invalid-feedback">
                {{ vv?.contactEmail?.$errors[0]?.$message }}
              </div>
            </div>

            <div class="form-group">
              <label for="salesRepId"
                >Are you currently working with a salesperson?</label
              >
              <select
                name="salesRepId"
                id="salesRepId"
                :class="{
                  'is-invalid': vv.salesRepId.$dirty && vv.salesRepId.$invalid,
                }"
                class="form-control form-control-lg"
                v-model="vv.salesRepId.$model"
              >
                <option value="" disabled>-----</option>
                <option
                  v-for="option in data.salesRepOptions"
                  :key="option.salesRepId"
                  :value="option.salesRepId"
                >
                  {{ option.name }}
                </option>
              </select>
              <div class="invalid-feedback">
                {{ vv?.salesRepId?.$errors[0]?.$message }}
              </div>
            </div>

            <div class="d-flex justify-content-center">
              <button
                type="submit"
                class="btn control-btn shadow-lg px-5 my-3 mx-auto"
                :disabled="vv.$invalid"
              >
                <div class="d-flex align-items-center justify-content-center">
                  <div class="p-2">Continue</div>
                  <BIconCaretRightFill />
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//#region imports
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength, email } from "@vuelidate/validators";
import { useRouter } from "vue-router";
import { reactive, toRef, inject, onMounted, ref } from "vue";
import api from "@/resources/axios";
//#endregion

export default {
  name: "FormStart",
  setup() {
    //#region variables and services
    const store = inject("store");
    const router = useRouter();
    const axios = api;
    const regex = /\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})/;
    //#endregion

    //#region refs
    const propertyNameRef = ref(null);
    //#endregion

    const data = reactive({
      salesRepOptions: [
        {
          salesRepId: "0",
          name: "Loading...",
        },
      ],
    });

    store.setLoading(true);
    axios
      .get("SalesReps")
      .then(function (response) {
        data.salesRepOptions = response.data;
      })
      .catch((error) => {
        store.toast.error("Server error occurred.");
        console.log("Error", error);
      })
      .then(function () {
        store.setLoading(false);
      });

    //#region vuelidate
    const fform = reactive({
      propertyName: "",
      propertyAddress: "",
      contactName: "",
      contactNumber: "",
      contactEmail: "",
      salesRepId: "",
    });

    const validPhoneNumber = (phoneNum) => {
      return regex.test(phoneNum);
    };

    const rules = {
      propertyName: { required, maxLength: maxLength(75) },
      propertyAddress: { required, maxLength: maxLength(100) },
      contactName: { required, maxLength: maxLength(100) },
      contactNumber: {
        required,
        validPhoneNumber,
      },
      contactEmail: { required, maxLength: maxLength(255), email },
      salesRepId: { required },
    };

    const vv = useVuelidate(rules, {
      propertyName: toRef(fform, "propertyName"),
      propertyAddress: toRef(fform, "propertyAddress"),
      contactName: toRef(fform, "contactName"),
      contactNumber: toRef(fform, "contactNumber"),
      contactEmail: toRef(fform, "contactEmail"),
      salesRepId: toRef(fform, "salesRepId"),
    });
    //#endregion

    //#region methods
    const onSubmit = () => {
      vv.value.$touch();

      if (vv.value.$invalid) return;

      store.setLoading(true);
      axios
        .post("Rfq", fform)
        .then((response) => {
          store.setRfqObj(response.data);
          router.push({ path: "door_select" });
        })
        .catch((error) => {
          store.toast.error("Error initializing RFQ.");
          console.log("Error", error);
        })
        .then(function () {
          store.setLoading(false);
        });
    };
    //#endregion

    //#region lifecycle hooks
    onMounted(() => {
      propertyNameRef.value.focus();
    });
    //#endregion

    return {
      router,
      vv,
      onSubmit,
      propertyNameRef,
      data,
    };
  },
};
</script>

<style scoped>
.error {
  color: red;
  font-size: smaller;
  font-style: italic;
  font-weight: 500;
  margin-top: 4px;
}
</style>
<template>
  <div id="rfqApp">
    <rfq-banner @cancel="onCancelClick" />
    <div id="mainApp" ref="mainApp" style="position: relative">
      <loading-overlay></loading-overlay>
      <div
        class="d-flex justify-content-center align-items-center h-100 text-white"
      >
        <router-view></router-view>
      </div>
    </div>
    <div class="footer">
      This site is protected by reCAPTCHA and the Google
      <a href="https://policies.google.com/privacy" target="_blank"
        >Privacy Policy</a
      >
      and
      <a href="https://policies.google.com/terms" target="_blank"
        >Terms of Service</a
      >
      apply.
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import store from "@/state/RfqApp/store";

import LoadingOverlay from "@/components/LoadingOverlay.vue";
import RfqBanner from "@/components/RfqBanner.vue";

export default {
  name: "RfqApp",

  provide: {
    store: store,
  },

  components: {
    LoadingOverlay,
    RfqBanner,
  },

  computed: {
    loadingContainer() {
      return this.$refs.mainApp;
    },
  },

  data() {
    return {
      state: store.state,
    };
  },

  watch: {
    $route: {
      handler: function (to) {
        if (!this.state.appStarted) {
          // prevent subpage navigation if app is not started
          if (to.fullPath != "/" && to.fullPath != "") {
            if (to.fullPath == "/success") {
              return;
            }
            this.$router.push("/");
          }
        }
      },
    },
  },

  methods: {
    onCancelClick() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },

    onDoorSelectContinueClick(doorType) {
      this.activeDoorType = doorType;
      this.doorPhotoActive = true;
      this.doorSelectActive = false;
    },
  },
};
</script>


<style lang="scss">
html,
body {
  height: 100%;
}

#app {
  height: 100%;
  width: 100vw;
}

#rfqApp {
  height: 100%;
  width: 100%;

  .control-btn {
    background-color: rgb(147, 151, 168);
    font-size: 1.5rem;
    font-weight: 700;
    height: 75px;
    max-height: 100%;
    border-color: #6c757d;
    color: #fff;
  }

  .btn-custom-lg {
    font-size: 1.5rem;
    font-weight: 700;
    height: 85px;
  }
}

.h-20 {
  height: 20% !important;
}

.h-40 {
  height: 40% !important;
}

.h-60 {
  height: 60% !important;
}

.w-20 {
  width: 20% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

#mainApp {
  // Subtract the footer and header from height
  height: calc(100% - 80px - 38px);
  width: 100%;
  background: linear-gradient(rgba(4, 8, 51, 0.65), rgba(4, 8, 51, 0.65)),
    url("./assets/img/main_background.jpg") no-repeat left 0% bottom 0% / cover;
}

.grecaptcha-badge {
  visibility: hidden;
}

.footer {
  z-index: 9999;
  position: fixed;
  bottom: 0%;
  left: 0%;
  background: rgba(105, 107, 133, 0.9);
  font-size: 0.8rem;
  text-align: center;
  height: 38px;
  width: 100%;
}

.footer a {
  color: #003e7c;
}

// .fixed-bottom {
//   bottom: 38px;
//   z-index: 10;
// }

/* to enlarge the toggles */
.custom-control-label {
  padding-top: 0.5rem;
  padding-left: 2rem;
  padding-bottom: 0.1rem;
  margin-left: 1rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  height: 2rem;
  width: 3.5rem;
  pointer-events: all;
  border-radius: 1rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(2rem - 4px);
  height: calc(2rem - 4px);
  background-color: #adb5bd;
  border-radius: 2rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(1.5rem);
  transform: translateX(1.5rem);
}
/* -- */

.divider {
  width: 100%;
  margin-bottom: 4px;
  height: 2px;
  background: grey;
}

.form-group {
  // put buttons behind the inputs
  position: relative;
  z-index: 20;
  margin-bottom: 0.75rem;
  margin-top: 0.75rem;
}

.form-card {
  position: relative;
  z-index: 15;
  background-color: rgba(255, 255, 255, 0.6);
  overflow: auto;
  // max-height: 90%;
}
.form-card-body {
  height: 100%;
  overflow: auto;
  padding: 1rem;
}
</style>
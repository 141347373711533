import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

import {
    BIconXCircle,
    BIconPlusCircleFill,
    BIconCaretRightFill,
    BIconCameraFill,
    BIconCheckCircleFill,
    BIconArrowRepeat
} from 'bootstrap-icons-vue';

const app = createApp(App);

app.component('BIconXCircle', BIconXCircle);
app.component('BIconPlusCircleFill', BIconPlusCircleFill);
app.component('BIconCaretRightFill', BIconCaretRightFill);
app.component('BIconCameraFill', BIconCameraFill);
app.component('BIconCheckCircleFill', BIconCheckCircleFill);
app.component('BIconArrowRepeat', BIconArrowRepeat);
app.use(router);

app.use(Toast, {
    position: POSITION.BOTTOM_RIGHT,
    timeout: 6000,
    transition: "Vue-Toastification__fade",
});

app.use(VueReCaptcha, {
    siteKey: '6LeddVwaAAAAABKQvWF03k1rdfL1Dj4WozbS9pJ_'
})

app.mount('#app');
// Axios setup
import axios from 'axios'

var baseURL;
if (process.env.NODE_ENV === 'production') {
    baseURL = 'https://api.topnotchinc.com/v1/advant/'
} else {
    baseURL = '/v1/Advant/'
}

const api = axios.create({
    headers: {
        'Content-type': 'application/json',
        'charset': 'utf-8',
        'Authorization': 'Bearer ' + process.env.VUE_APP_API_TOKEN
    },
    baseURL: baseURL
});

export default api;
<template>
  <div id="rfqBanner">
    <div
      class="d-flex justify-content-around align-items-center p-1 flex-wrap h-100"
    >
      <div class="w-20 px-3">
        <button
          class="btn btn-danger"
          @click="cancelClick"
          :disabled="store.state.isLoading"
        >
          <BIconXCircle />
        </button>
      </div>
      <div class="w-60 h-100 d-flex justify-content-center align-items-center">
        <a target="_blank" href="https://advant-solutions.com" class="h-100 d-flex align-items-center">
          <img class="logo" src="@/assets/img/advant-logo.png" alt="Logo" />
        </a>
      </div>
      <div class="w-20"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RfqBanner",
  inject: ["store"],
  methods: {
    cancelClick() {
      if (this.store.state.appCompleted) {
        this.store.cancelApp();
        this.$router.push({ path: "/" });
      } else {
        if (
          confirm(
            "Are you sure you want to cancel your RFQ App? All data submitted so far will be lost."
          )
        ) {
          this.store.cancelApp();
          this.$router.push({ path: "/" });
        }
      }
    },
  },
};
</script>

<style scoped>
#rfqBanner {
  background-color: rgba(105, 107, 133, 0.7);
  height: 80px;
}

.logo {
  max-width: 100%;
  max-height: 100%;
}
</style>
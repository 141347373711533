<template>
  <div class="container h-100">
    <div class="d-flex flex-column h-100 justify-content-center">
      <div
        v-if="store.state.appCompleted"
        class="text-center alert alert-success"
      >
        <h3>
          Thank you. We will contact you within 48 hours regarding your request.
        </h3>
        <h5 class="mt-4">
          Your RFQ Submission ID is: <b>{{ store.getRfqObj().rfqId }}</b
          >. Please make note of this for your records.
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormSuccess",
  inject: ["store"],
};
</script>

<style lang="scss" scoped>
h3 {
  font-weight: 600;
  line-height: 2.5rem;
}
</style>

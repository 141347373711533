import { reactive, readonly } from "vue";
import { useToast } from "vue-toastification";

const toast = useToast();

const state = reactive({
    isLoading: false,
    appStarted: false,
    appCompleted: false,
    selectedDoor: {},
    completedDoors: [],
    rfq: {}
});

const setLoading = function (status) {
    state.isLoading = status;
};

const startApp = function () {
    // prompt to prevent accidental page refreshes and data loss
    window.addEventListener("beforeunload", onPageReload);

    state.appStarted = true;
};

const onPageReload = function (event) {
    // prompt to prevent accidental page refreshes and data loss
    event.preventDefault();
    event.returnValue = "";
};

const cancelApp = function () {
    // remove listener for refresh prompt
    window.removeEventListener("beforeunload", onPageReload);

    // reset app state
    state.appStarted = false;
    state.appCompleted = false;
    state.selectedDoor = {};
    state.completedDoors = [];
    state.rfq = {};
};

const completeApp = function () {
    // remove listener for refresh prompt
    window.removeEventListener("beforeunload", onPageReload);

    state.appCompleted = true;
    state.appStarted = false;
    return state.appCompleted;
};

const selectDoorType = function (selectedDoor) {
    state.selectedDoor = selectedDoor;
};

const markDoorTypeComplete = function () {
    state.completedDoors.push(state.selectedDoor.doorTypeId);
};

const setRfqObj = function (rfq) {
    state.rfq = rfq;
};

const getRfqObj = function () {
    return state.rfq;
};

const setRfqDetails = function (rfqDetailForm) {
    state.rfq.accessControl = rfqDetailForm.accessControl;
    state.rfq.accessControlBrand = rfqDetailForm.accessControlBrand;
    state.rfq.accessControlAccessPoints = rfqDetailForm.accessControlAccessPoints;
    state.rfq.comments = rfqDetailForm.comments;
};

export default {
    state: readonly(state), setLoading,
    startApp, cancelApp, completeApp,
    selectDoorType, markDoorTypeComplete,
    onPageReload, setRfqObj, getRfqObj,
    setRfqDetails, toast
};